/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Layout from "~components/Layout";
import Parallaxer from "~components/Parallaxer";
import SEO from "~components/SEO";

const SitePage = ({ data, location }) => {
  const { setBackground } = useContext(AppContext);
  const { device } = useContext(DocumentContext);
  const { frontmatter } = data.markdownRemark;

  //

  useEffect(() => {
    setBackground(frontmatter.backgroundColor);
  }, []);

  //

  const sectionJSX = [];

  if (frontmatter?.sections?.[0]) {
    frontmatter.sections.forEach(section => {
      switch (section.type) {
        case `image`:
          sectionJSX.push(
            <section className="animation-appear-slow animation-delay-3 grid-end-8 xs:grid-end-12 grid-start-3 xs:grid-start-1 mb-6">
              <Img
                className="w-full relative block"
                fluid={section.image.childImageSharp.fluid}
                alt=""
              />
            </section>
          );

          break;

        // TODO : soundcloud
        case `iframe`:
          sectionJSX.push(
            <section className="animation-appear-slow animation-delay-3 grid-end-8 xs:grid-end-12 grid-start-3 xs:grid-start-1 mb-6">
              <div
                className="vimeo"
                dangerouslySetInnerHTML={{ __html: section.iframe }}
              ></div>
            </section>
          );

          break;

        case `text`:
          sectionJSX.push(
            <section className="animation-appear-slow animation-delay-3 grid-end-8 xs:grid-end-12 grid-start-3 xs:grid-start-1 mb-6">
              <div
                className="project-page__content w-full relative"
                dangerouslySetInnerHTML={{ __html: section.text }}
              ></div>
            </section>
          );

          break;

        default:
          break;
      }
    });
  }

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="site-page w-full relative">
        <section className="site-page__banner w-full sixteen-by-nine relative block overflow-hidden">
          {frontmatter.bannerText && frontmatter.bannerText !== `` && (
            <section className="w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 py-3 px-4">
              <h2 className={`${device === `mobile` ? `f2` : `f1`} text-white`}>
                {frontmatter.bannerText}
              </h2>
            </section>
          )}

          <Parallaxer atTop reverse severe>
            <Img
              className="w-full relative block"
              fluid={frontmatter.bannerImage.childImageSharp.fluid}
              alt={frontmatter.title}
            />
          </Parallaxer>
        </section>

        <section className="site-page__header w-full relative">
          <div className="grid">
            <div className="grid-end-12 pt-1 caption uppercase">INFO</div>
          </div>

          <div className="site-page__header__meta relative">
            <header className="grid pt-2 pb-1">
              <h2 className="grid-end-3 md:grid-end-4 xs:grid-end-12 b1">
                {frontmatter.infoLeft}
              </h2>

              <h2 className="grid-end-3 md:grid-end-4 xs:grid-end-12 grid-start-7 xs:grid-start-1 xs:mt-2 xs:mb-2 b1">
                {frontmatter.infoRight}
              </h2>
            </header>
          </div>
        </section>

        <div className="grid pt-16 xs:pt-6 pb-24 xs:pb-12">
          <h1 className="animation-appear-slow grid-end-8 xs:grid-end-12 grid-start-3 xs:grid-start-1 pt-2 pb-2 mb-6 xs:mb-2 g1">
            {frontmatter.title}
          </h1>

          {sectionJSX}
        </div>
      </Layout>
    </>
  );
};

export default SitePage;

export const query = graphql`
  query SitePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        backgroundColor
        bannerText
        bannerImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        infoLeft
        infoRight
        sections {
          type
          text
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          iframe
        }
        seoDescription
        seoKeywords
      }
    }
  }
`;
